.header {
  position: fixed;
  width: 100%;
  z-index: 10;
  backdrop-filter: blur(4px);
  padding: var(--default-padding);

  &__content {
    display: flex;
    align-items: center;
    z-index: 1;
    position: relative;

    @media (min-width: 1024px) {
      justify-content: space-between;
    }
  }

  &__logotype {
    display: block;
    width: 20px;
    height: 32px;

    @media (min-width: 1024px) {
      width: 28px;
      height: 46px;
    }
  }

  &__col {
    display: flex;
    &_1 {
      flex: 1;
      justify-content: start;
    }
    &_2 {
      flex: 2;
      justify-content: center;
    }
    &_3 {
      justify-content: end;
      @media (min-width: 640px) {
        flex: 1;
      }
    }
  }
}

.navigation {
  &__list {
    list-style: none;

    &_desktop {
      display: none;

      @media (min-width: 1024px) {
        display: flex;
        align-items: center;
      }
    }

    &_mobile {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
    }
  }

  &__link {
    padding: 12px;
  }

  &_mobile {
    margin-left: 8px;
    @media (min-width: 1024px) {
      display: none;
    }
  }

  &__burger {
    display: block;
    width: 42px;
    height: 42px;
    background-image: url("/src/assets/icons/burger.svg");
    background-color: var(--background-secondary);
    background-repeat: no-repeat;
    border-radius: 12px;
    cursor: pointer;
    background-position: center;
    border: none;
  }

  &__content {
    visibility: visible;
    position: absolute;
    z-index: 20;
    background: var(--background-secondary);
    top: calc(42px + var(--default-padding));
    right: 0;
    border-radius: 12px;

    &_hidden {
      visibility: hidden;
    }
  }
}
