.unlock {
  --unlock-border-active: #ffffff;
  --unlock-border-inactive: #4b5563;

  margin-top: 102px;
  background: var(--background-secondary);
  padding-top: 56px;
  padding-bottom: 56px;

  overflow: hidden;
  position: relative;

  &__body {
    display: flex;
    flex-wrap: wrap;
    gap: 62px;
    position: relative;
  }

  &__information {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 4;
    color: var(--text-primary);
    z-index: 2;
    position: relative;
  }

  &__link {
    margin-top: 24px;
  }

  &__images {
    flex: 8;
    display: flex;
    justify-content: end;
    position: relative;

    &:after {
      content: "";
      border-radius: 50%;
      width: 265px;
      height: 265px;
      background: #3621b3;
      filter: blur(120.463px);
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(-66%, -30%);
      z-index: 1;
    }
  }

  &__img {
    position: relative;
    z-index: 2;
    right: -40px;
    width: 604px;
    height: auto;
    aspect-ratio: 16/9;
  }

  &__bg {
    position: absolute;
    object-fit: contain;
    top: -91px;
    right: -178px;
    z-index: 1;
  }
  @media (max-width: 1024px) {
    &__images {
      display: none;
    }
  }
}

.unlock-list {
  flex: 100%;
  display: flex;
  gap: 32px;

  &__item {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 12px;
    padding-top: 12px;
    border-top: 1.4px solid var(--unlock-border-inactive);
    z-index: 2;

    &:hover {
      border-top: 1.4px solid var(--unlock-border-active);
    }
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-primary);
  }

  &__description {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-secondary);
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
