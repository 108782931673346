.hero {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 32px;
    position: relative;
    width: max-content;
  }

  &__title {
    font-weight: 300;
    font-size: 62px;
    line-height: 74px;
  }

  &__hint {
    font-weight: 400;
    font-size: 19px;
    line-height: 23px;
  }

  &__bg {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    z-index: -1;
  }
  &__box {
    position: absolute;
    pointer-events: none;
    &_1 {
      width: 225px;
      height: auto;
      aspect-ratio: 3/4;
      top: 0;
      left: 0;
      transform: translate(-75%, -50%);
    }

    &_2 {
      width: 613px;
      height: auto;
      aspect-ratio: 4/3;
      right: 0;
      bottom: 0;
      transform: translate(75%, 70%);
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-weight: 400;
      font-size: 32px;
      line-height: 42px;
    }

    &__hint {
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media (max-width: 1024px) {
    &__box {
      display: none;
    }
  }
}
