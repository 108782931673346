.solutions {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 54px;

  &__content {
    display: grid;
    max-width: 100%;
    grid-template-columns: repeat(2, 50%);
    border-radius: 24px;
    overflow: hidden;
    margin-top: 61px;

    @media (max-width: 1024px) {
      grid-template-columns: 100%;
    }
  }

  &__col {
    &_1 {
      background-color: var(--background-secondary);
      padding: 64px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
    }

    &_2 {
      background: linear-gradient(89.09deg, #9104ff 11%, #2a3ac8 99.22%);
      overflow: auto;
      display: flex;
      flex-direction: column;
    }
  }

  &__icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
  }

  &__icon {
    display: block;
    width: 68px;
    height: auto;
    aspect-ratio: 1/1;

    &_5 {
      width: 40px;
      opacity: 0.5;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: var(--text-primary);
  }

  &__description {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: var(--text-primary);
  }

  &__hint {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-primary);
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__tabs {
    display: flex;
    align-items: center;
    background-color: rgba(25, 25, 25, 0.3);
    position: sticky;
    left: 0;
  }

  &__tab {
    color: rgba(255, 255, 255, 0.8);
    padding: 8px 20px;
    &_active {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}
