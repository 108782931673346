.get-in-touch {
  margin-top: 61px;
  background: linear-gradient(
    90deg,
    var(--color-primary) 11%,
    var(--color-secondary) 99%
  );
  border-radius: 24px;
  display: flex;
  gap: 24px;
  padding: 36px 42px;
  overflow: hidden;

  &__col {
    display: flex;

    &_1 {
      flex-direction: column;
      gap: 40px;
      align-items: start;
      flex: 1;
    }

    &_2 {
      flex: 2;
      position: relative;
      justify-content: center;
      align-items: center;

      @media (max-width: 1024px) {
        display: none;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__title {
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    color: var(--text-primary);

    @media (min-width: 768px) {
      font-size: 42px;
      line-height: 50px;
    }
  }

  &__description {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--text-secondary);

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__box {
    position: absolute;
  }
}
