.scale {
  --scale-item-background: #000000;

  margin-top: 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &__list {
    margin-top: 30px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
  }

  &__item {
    background-color: var(--scale-item-background);
    border-radius: 24px;
    text-align: left;
    padding: 48px;
    display: flex;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    &_1 {
      padding-bottom: 0;
      flex-direction: column;
      gap: 20px;
    }

    &_2 {
      padding: 4px;
      flex-direction: column;
      gap: 40px;
      border: 14px solid var(--scale-item-background);
      background: linear-gradient(
        180deg,
        var(--color-primary) -9.68%,
        var(--scale-item-background) 89.43%
      );
    }

    &_3 {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 3;
      align-items: end;
      gap: 87px;
      overflow: hidden;
    }
  }

  &__information {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: start;
  }

  &__title {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: var(--text-primary);

    &_sm {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__content {
    background-color: var(--scale-item-background);
    padding: 36px;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
  }

  &__description {
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: var(--text-primary);

    &_sm {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: var(--text-secondary);
    }
  }

  &__picture {
    display: flex;
    justify-content: center;

    &_3 {
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        height: 200px;
        width: 298px;
        background-color: var(--color-tertiary);
        opacity: 0.3;
        filter: blur(77.0664px);
        transform: rotate(-165deg);
      }

      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        height: 362px;
        width: 510px;
        background: linear-gradient(
          180deg,
          var(--color-secondary) 0%,
          var(--color-primary) 100%
        );
        filter: blur(154.133px);
        transform: matrix(0.97, 0.26, 0.26, -0.97, 0, 0);
      }
    }
  }

  &__img {
    display: block;

    &_1 {
      width: 100%;
      height: auto;
      aspect-ratio: 4/3;
      margin-bottom: -4px;
    }
    &_2 {
      width: 100%;
      height: auto;
      aspect-ratio: 16/9;
    }

    &_3 {
      width: 580px;
      height: auto;
      aspect-ratio: 16/9;

      @media (max-width: 1280px) {
        width: 420px;
      }
      @media (max-width: 1024px) {
        width: 0;
      }
    }
  }

  @media (max-width: 1024px) {
    &__list {
      grid-template-columns: 1fr;
    }
    &__item {
      &_3 {
        grid-row-start: auto;
        grid-column-start: auto;
        grid-column-end: auto;
      }
    }
    &__picture {
      &_3 {
        display: none;
      }
    }
  }

  @media (max-width: 640px) {
    &__img {
      width: 100%;
      max-width: 489px;
    }
  }
}
