.footer {
  --footer-border-color: #272727;

  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--footer-border-color);
  padding-top: 24px;
  padding-bottom: 24px;
  margin-top: 74px;

  &__rights {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: var(--text-primary);
  }

  &__email {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-primary);
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
}
