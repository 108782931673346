.about {
  margin-top: 48px;

  &__title {
    color: var(--text-primary);
    font-weight: 400;
    font-size: 42px;
    line-height: 50px;
    &_colored {
      background-image: linear-gradient(
        45deg,
        var(--color-secondary),
        var(--color-primary)
      );
      background-size: 100%;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  &__description {
    color: var(--text-primary);
    font-weight: 300;
    font-size: 24px;
    line-height: 29px;
    margin-top: 8px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    margin-top: 44px;
  }

  @media (max-width: 1024px) {
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px;
      margin-top: 44px;
    }
  }

  @media (max-width: 768px) {
    &__title {
      font-weight: 400;
      font-size: 32px;
      line-height: 40px;
    }

    &__description {
      font-weight: 300;
      font-size: 16px;
      line-height: 19px;
    }
  }

  @media (max-width: 640px) {
    &__list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 30px;
      margin-top: 44px;
    }
  }
}

.about-card {
  position: sticky;
  border-radius: 24px;
  padding: 1px;
  flex: 1;
  overflow: hidden;

  &:hover {
    &:before {
      content: "";
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      width: 200%;
      height: 200%;
      background-repeat: no-repeat;
      background-image: linear-gradient(
        90deg,
        var(--background-secondary) 20%,
        var(--color-primary) 100%
      );
      animation: rotate 4s linear infinite;
    }
  }

  &__content {
    position: relative;
    background-color: var(--background-secondary);
    border-radius: 24px;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &_3 {
      position: relative;
      overflow: hidden;
      &:before {
        content: "";
        border-radius: 50%;
        width: 265px;
        height: 265px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          var(--background-secondary) 48.44%,
          transparent 100%
        );
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-50%, -40%);
        z-index: 3;
      }

      &:after {
        content: "";
        border-radius: 50%;
        width: 265px;
        height: 265px;
        background: radial-gradient(
          50% 50% at 50% 50%,
          var(--background-secondary) 48.44%,
          transparent 100%
        );
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translate(60%, -30%);
        z-index: 3;
      }
    }
  }

  &__image {
    display: flex;
    align-self: center;
    flex: 1;
    z-index: 2;

    &_1 {
      width: 362px;
      height: auto;
      aspect-ratio: 4/3;
      object-fit: contain;
    }

    &_2 {
      width: 225px;
      height: auto;
      aspect-ratio: 4/3;
      object-fit: contain;
    }

    &_3 {
      width: 544px;
      height: auto;
      aspect-ratio: 16/9;
      object-fit: contain;
    }
  }

  &__bg {
    position: absolute;
    object-fit: contain;
    align-self: center;
    z-index: 1;

    &_2 {
      top: -25%;
    }
  }

  &__title {
    color: var(--text-primary);
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin-top: 42px;
  }

  &__description {
    color: var(--text-primary);
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    margin-top: 6px;
  }

  @media (max-width: 1024px) {
    &_3 {
      grid-row-start: 2;
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  @media (max-width: 640px) {
    &_3 {
      grid-row-start: auto;
      grid-column-start: auto;
      grid-column-end: auto;
    }
  }
}
