.partners {
  flex-direction: column;
  align-items: start;
  margin-top: 54px;
  display: flex;

  &__list {
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 38px;
    gap: 28px;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__item {
    width: 100%;
    display: flex;
    height: 172px;

    &:before {
      content: "";
      position: absolute;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      background-image: linear-gradient(
        -135deg,
        var(--background-secondary) 20%,
        var(--color-primary) 100%
      );
      top: -1px;
      left: -1px;
      border-radius: 24px;
    }
  }

  &__link {
    background: var(--background-secondary);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    overflow: hidden;
    position: relative;

    &_1 {
      &:after {
        content: "";
        position: absolute;
        background-image: url("/src/assets/icons/blur.svg");
        background-repeat: no-repeat;
        width: 774px;
        height: 864px;
        background-size: contain;
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
      }
    }
    &_2 {
      &:after {
        content: "";
        position: absolute;
        background-image: url("/src/assets/icons/blur.svg");
        background-repeat: no-repeat;
        width: 774px;
        height: 864px;
        background-size: contain;
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
      }
    }
  }

  &__img {
    &_1 {
    }
    &_2 {
    }
  }
}
