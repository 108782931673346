.compliance {
  margin-top: 104px;
  display: flex;
  align-items: center;
  gap: 113px;

  &__images {
    flex: 8;
    height: 553px;

    &_1 {
      position: absolute;
      width: 1091px;
      height: auto;
      aspect-ratio: 16/9;
      &_mobile {
        display: none;
      }
    }
  }

  &__information {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 4;
  }

  @media (max-width: 1024px) {
    gap: 31px;

    &__images {
      flex: 1;
      height: 553px;

      &_1 {
        display: none;
        &_mobile {
          width: 344px;
          height: auto;
          aspect-ratio: 9/16;
          display: block;
        }
      }
    }
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
}
