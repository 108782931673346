.header {
  width: 100%;
  z-index: 10;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  padding: var(--default-padding);
  position: fixed;
}

.header__content {
  z-index: 1;
  align-items: center;
  display: flex;
  position: relative;
}

@media (min-width: 1024px) {
  .header__content {
    justify-content: space-between;
  }
}

.header__logotype {
  width: 20px;
  height: 32px;
  display: block;
}

@media (min-width: 1024px) {
  .header__logotype {
    width: 28px;
    height: 46px;
  }
}

.header__col {
  display: flex;
}

.header__col_1 {
  flex: 1;
  justify-content: start;
}

.header__col_2 {
  flex: 2;
  justify-content: center;
}

.header__col_3 {
  justify-content: end;
}

@media (min-width: 640px) {
  .header__col_3 {
    flex: 1;
  }
}

.navigation__list {
  list-style: none;
}

.navigation__list_desktop {
  display: none;
}

@media (min-width: 1024px) {
  .navigation__list_desktop {
    align-items: center;
    display: flex;
  }
}

.navigation__list_mobile {
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 24px;
  display: flex;
}

.navigation__link {
  padding: 12px;
}

.navigation_mobile {
  margin-left: 8px;
}

@media (min-width: 1024px) {
  .navigation_mobile {
    display: none;
  }
}

.navigation__burger {
  width: 42px;
  height: 42px;
  background-image: url("burger.b99be005.svg");
  background-color: var(--background-secondary);
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  border-radius: 12px;
  display: block;
}

.navigation__content {
  visibility: visible;
  z-index: 20;
  background: var(--background-secondary);
  top: calc(42px + var(--default-padding));
  border-radius: 12px;
  position: absolute;
  right: 0;
}

.navigation__content_hidden {
  visibility: hidden;
}

.hero {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.hero__content {
  text-align: center;
  width: max-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  display: flex;
  position: relative;
}

.hero__title {
  font-size: 62px;
  font-weight: 300;
  line-height: 74px;
}

.hero__hint {
  font-size: 19px;
  font-weight: 400;
  line-height: 23px;
}

.hero__bg {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hero__box {
  pointer-events: none;
  position: absolute;
}

.hero__box_1 {
  width: 225px;
  height: auto;
  aspect-ratio: 3 / 4;
  top: 0;
  left: 0;
  transform: translate(-75%, -50%);
}

.hero__box_2 {
  width: 613px;
  height: auto;
  aspect-ratio: 4 / 3;
  bottom: 0;
  right: 0;
  transform: translate(75%, 70%);
}

@media (max-width: 768px) {
  .hero__title {
    font-size: 32px;
    font-weight: 400;
    line-height: 42px;
  }

  .hero__hint {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
}

@media (max-width: 1024px) {
  .hero__box {
    display: none;
  }
}

.about {
  margin-top: 48px;
}

.about__title {
  color: var(--text-primary);
  font-size: 42px;
  font-weight: 400;
  line-height: 50px;
}

.about__title_colored {
  background-image: linear-gradient(45deg, var(--color-secondary), var(--color-primary));
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about__description {
  color: var(--text-primary);
  margin-top: 8px;
  font-size: 24px;
  font-weight: 300;
  line-height: 29px;
}

.about__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 44px;
  display: grid;
}

@media (max-width: 1024px) {
  .about__list {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 44px;
    display: grid;
  }
}

@media (max-width: 768px) {
  .about__title {
    font-size: 32px;
    font-weight: 400;
    line-height: 40px;
  }

  .about__description {
    font-size: 16px;
    font-weight: 300;
    line-height: 19px;
  }
}

@media (max-width: 640px) {
  .about__list {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-top: 44px;
    display: grid;
  }
}

.about-card {
  border-radius: 24px;
  flex: 1;
  padding: 1px;
  position: sticky;
  overflow: hidden;
}

.about-card:hover:before {
  content: "";
  z-index: -2;
  width: 200%;
  height: 200%;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, var(--background-secondary) 20%, var(--color-primary) 100%);
  animation: 4s linear infinite rotate;
  position: absolute;
  top: -50%;
  left: -50%;
}

.about-card__content {
  background-color: var(--background-secondary);
  height: 100%;
  border-radius: 24px;
  flex-direction: column;
  padding: 24px 32px;
  display: flex;
  position: relative;
}

.about-card__content_3 {
  position: relative;
  overflow: hidden;
}

.about-card__content_3:before {
  content: "";
  width: 265px;
  height: 265px;
  background: radial-gradient(50% 50% at 50% 50%, var(--background-secondary) 48.44%, transparent 100%);
  z-index: 3;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -40%);
}

.about-card__content_3:after {
  content: "";
  width: 265px;
  height: 265px;
  background: radial-gradient(50% 50% at 50% 50%, var(--background-secondary) 48.44%, transparent 100%);
  z-index: 3;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(60%, -30%);
}

.about-card__image {
  z-index: 2;
  flex: 1;
  align-self: center;
  display: flex;
}

.about-card__image_1 {
  width: 362px;
  height: auto;
  aspect-ratio: 4 / 3;
  object-fit: contain;
}

.about-card__image_2 {
  width: 225px;
  height: auto;
  aspect-ratio: 4 / 3;
  object-fit: contain;
}

.about-card__image_3 {
  width: 544px;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: contain;
}

.about-card__bg {
  object-fit: contain;
  z-index: 1;
  align-self: center;
  position: absolute;
}

.about-card__bg_2 {
  top: -25%;
}

.about-card__title {
  color: var(--text-primary);
  margin-top: 42px;
  font-size: 18px;
  font-weight: 400;
  line-height: 18px;
}

.about-card__description {
  color: var(--text-primary);
  margin-top: 6px;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

@media (max-width: 1024px) {
  .about-card_3 {
    grid-column: 1 / 3;
    grid-row-start: 2;
  }
}

@media (max-width: 640px) {
  .about-card_3 {
    grid-column: auto;
    grid-row-start: auto;
  }
}

.unlock {
  --unlock-border-active: #fff;
  --unlock-border-inactive: #4b5563;
  background: var(--background-secondary);
  margin-top: 102px;
  padding-top: 56px;
  padding-bottom: 56px;
  position: relative;
  overflow: hidden;
}

.unlock__body {
  flex-wrap: wrap;
  gap: 62px;
  display: flex;
  position: relative;
}

.unlock__information {
  color: var(--text-primary);
  z-index: 2;
  flex-direction: column;
  flex: 4;
  align-items: start;
  display: flex;
  position: relative;
}

.unlock__link {
  margin-top: 24px;
}

.unlock__images {
  flex: 8;
  justify-content: end;
  display: flex;
  position: relative;
}

.unlock__images:after {
  content: "";
  width: 265px;
  height: 265px;
  filter: blur(120.463px);
  z-index: 1;
  background: #3621b3;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(-66%, -30%);
}

.unlock__img {
  z-index: 2;
  width: 604px;
  height: auto;
  aspect-ratio: 16 / 9;
  position: relative;
  right: -40px;
}

.unlock__bg {
  object-fit: contain;
  z-index: 1;
  position: absolute;
  top: -91px;
  right: -178px;
}

@media (max-width: 1024px) {
  .unlock__images {
    display: none;
  }
}

.unlock-list {
  flex: 100%;
  gap: 32px;
  display: flex;
}

.unlock-list__item {
  border-top: 1.4px solid var(--unlock-border-inactive);
  z-index: 2;
  flex-direction: column;
  flex: 1;
  gap: 12px;
  padding-top: 12px;
  display: flex;
}

.unlock-list__item:hover {
  border-top: 1.4px solid var(--unlock-border-active);
}

.unlock-list__title {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.unlock-list__description {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

@media (max-width: 1024px) {
  .unlock-list {
    flex-direction: column;
  }
}

.compliance {
  align-items: center;
  gap: 113px;
  margin-top: 104px;
  display: flex;
}

.compliance__images {
  height: 553px;
  flex: 8;
}

.compliance__images_1 {
  width: 1091px;
  height: auto;
  aspect-ratio: 16 / 9;
  position: absolute;
}

.compliance__images_1_mobile {
  display: none;
}

.compliance__information {
  flex-direction: column;
  flex: 4;
  align-items: start;
  display: flex;
}

@media (max-width: 1024px) {
  .compliance {
    gap: 31px;
  }

  .compliance__images {
    height: 553px;
    flex: 1;
  }

  .compliance__images_1 {
    display: none;
  }

  .compliance__images_1_mobile {
    width: 344px;
    height: auto;
    aspect-ratio: 9 / 16;
    display: block;
  }
}

@media (max-width: 640px) {
  .compliance {
    flex-direction: column;
  }
}

.scale {
  --scale-item-background: #000;
  text-align: center;
  flex-direction: column;
  align-items: center;
  margin-top: 71px;
  display: flex;
}

.scale__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-top: 30px;
  display: grid;
}

.scale__item {
  background-color: var(--scale-item-background);
  text-align: left;
  border-radius: 24px;
  justify-content: space-between;
  padding: 48px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.scale__item_1 {
  flex-direction: column;
  gap: 20px;
  padding-bottom: 0;
}

.scale__item_2 {
  border: 14px solid var(--scale-item-background);
  background: linear-gradient(180deg, var(--color-primary) -9.68%, var(--scale-item-background) 89.43%);
  flex-direction: column;
  gap: 40px;
  padding: 4px;
}

.scale__item_3 {
  grid-column: 1 / 3;
  grid-row-start: 2;
  align-items: end;
  gap: 87px;
  overflow: hidden;
}

.scale__information {
  flex-direction: column;
  align-items: start;
  gap: 12px;
  display: flex;
}

.scale__title {
  color: var(--text-primary);
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.scale__title_sm {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.scale__content {
  background-color: var(--scale-item-background);
  height: 100%;
  border-radius: 8px;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding: 36px;
  display: flex;
}

.scale__description {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
}

.scale__description_sm {
  color: var(--text-secondary);
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

.scale__picture {
  justify-content: center;
  display: flex;
}

.scale__picture_3 {
  z-index: 1;
}

.scale__picture_3:after {
  content: "";
  z-index: -1;
  height: 200px;
  width: 298px;
  background-color: var(--color-tertiary);
  opacity: .3;
  filter: blur(77.0664px);
  position: absolute;
  transform: rotate(-165deg);
}

.scale__picture_3:before {
  content: "";
  z-index: -1;
  height: 362px;
  width: 510px;
  background: linear-gradient(180deg, var(--color-secondary) 0%, var(--color-primary) 100%);
  filter: blur(154.133px);
  position: absolute;
  transform: matrix(.97, .26, .26, -.97, 0, 0);
}

.scale__img {
  display: block;
}

.scale__img_1 {
  width: 100%;
  height: auto;
  aspect-ratio: 4 / 3;
  margin-bottom: -4px;
}

.scale__img_2 {
  width: 100%;
  height: auto;
  aspect-ratio: 16 / 9;
}

.scale__img_3 {
  width: 580px;
  height: auto;
  aspect-ratio: 16 / 9;
}

@media (max-width: 1280px) {
  .scale__img_3 {
    width: 420px;
  }
}

@media (max-width: 1024px) {
  .scale__img_3 {
    width: 0;
  }

  .scale__list {
    grid-template-columns: 1fr;
  }

  .scale__item_3 {
    grid-column: auto;
    grid-row-start: auto;
  }

  .scale__picture_3 {
    display: none;
  }
}

@media (max-width: 640px) {
  .scale__img {
    width: 100%;
    max-width: 489px;
  }
}

.solutions {
  flex-direction: column;
  align-items: start;
  margin-top: 54px;
  display: flex;
}

.solutions__content {
  max-width: 100%;
  border-radius: 24px;
  grid-template-columns: repeat(2, 50%);
  margin-top: 61px;
  display: grid;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .solutions__content {
    grid-template-columns: 100%;
  }
}

.solutions__col_1 {
  background-color: var(--background-secondary);
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
  padding: 64px;
  display: flex;
}

.solutions__col_2 {
  background: linear-gradient(89.09deg, #9104ff 11%, #2a3ac8 99.22%);
  flex-direction: column;
  display: flex;
  overflow: auto;
}

.solutions__icons {
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  display: flex;
}

.solutions__icon {
  width: 68px;
  height: auto;
  aspect-ratio: 1 / 1;
  display: block;
}

.solutions__icon_5 {
  width: 40px;
  opacity: .5;
}

.solutions__text {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.solutions__title {
  color: var(--text-primary);
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
}

.solutions__description {
  color: var(--text-primary);
  font-size: 18px;
  font-weight: 300;
  line-height: 22px;
}

.solutions__hint {
  color: var(--text-primary);
  align-items: center;
  gap: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  display: flex;
}

.solutions__tabs {
  background-color: #1919194d;
  align-items: center;
  display: flex;
  position: sticky;
  left: 0;
}

.solutions__tab {
  color: #fffc;
  padding: 8px 20px;
}

.solutions__tab_active {
  background: #ffffff1a;
}

.partners {
  flex-direction: column;
  align-items: start;
  margin-top: 54px;
  display: flex;
}

.partners__list {
  width: 100%;
  align-items: center;
  gap: 28px;
  margin-top: 38px;
  list-style: none;
  display: flex;
}

@media (max-width: 768px) {
  .partners__list {
    flex-direction: column;
  }
}

.partners__item {
  width: 100%;
  height: 172px;
  display: flex;
}

.partners__item:before {
  content: "";
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  background-image: linear-gradient(-135deg, var(--background-secondary) 20%, var(--color-primary) 100%);
  border-radius: 24px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.partners__link {
  background: var(--background-secondary);
  width: 100%;
  height: 100%;
  border-radius: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.partners__link_1:after {
  content: "";
  width: 774px;
  height: 864px;
  background-image: url("blur.149fc3e7.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%);
}

.partners__link_2:after {
  content: "";
  width: 774px;
  height: 864px;
  background-image: url("blur.149fc3e7.svg");
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
}

.get-in-touch {
  background: linear-gradient(90deg, var(--color-primary) 11%, var(--color-secondary) 99%);
  border-radius: 24px;
  gap: 24px;
  margin-top: 61px;
  padding: 36px 42px;
  display: flex;
  overflow: hidden;
}

.get-in-touch__col {
  display: flex;
}

.get-in-touch__col_1 {
  flex-direction: column;
  flex: 1;
  align-items: start;
  gap: 40px;
}

.get-in-touch__col_2 {
  flex: 2;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: 1024px) {
  .get-in-touch__col_2 {
    display: none;
  }
}

.get-in-touch__content {
  flex-direction: column;
  gap: 4px;
  display: flex;
}

.get-in-touch__title {
  color: var(--text-primary);
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
}

@media (min-width: 768px) {
  .get-in-touch__title {
    font-size: 42px;
    line-height: 50px;
  }
}

.get-in-touch__description {
  color: var(--text-secondary);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@media (min-width: 768px) {
  .get-in-touch__description {
    font-size: 18px;
    line-height: 22px;
  }
}

.get-in-touch__box {
  position: absolute;
}

.footer {
  --footer-border-color: #272727;
  border-top: 1px solid var(--footer-border-color);
  justify-content: space-between;
  align-items: center;
  margin-top: 74px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
}

.footer__rights {
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
}

.footer__email {
  color: var(--text-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

@media (max-width: 640px) {
  .footer {
    flex-direction: column;
  }
}

body section {
  position: relative;
}

body section:nth-child(1) {
  --section-index: 1;
  z-index: calc(var(--section-index)  + 1);
}

body section:nth-child(2) {
  --section-index: 2;
  z-index: calc(var(--section-index)  + 1);
}

body section:nth-child(3) {
  --section-index: 3;
  z-index: calc(var(--section-index)  + 1);
}

body section:nth-child(4) {
  --section-index: 4;
  z-index: calc(var(--section-index)  + 1);
}

body section:nth-child(5) {
  --section-index: 5;
  z-index: calc(var(--section-index)  + 1);
}

body section:nth-child(6) {
  --section-index: 6;
  z-index: calc(var(--section-index)  + 1);
}

body section:nth-child(7) {
  --section-index: 7;
  z-index: calc(var(--section-index)  + 1);
}

/*# sourceMappingURL=index.98268468.css.map */
