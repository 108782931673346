@import "header";
@import "hero";
@import "about";
@import "unlock";
@import "compliance";
@import "scale";
@import "solutions";
@import "partners";
@import "get-in-touch";
@import "footer";

body section {
  position: relative;

  @for $i from 1 to 8 {
    &:nth-child(#{$i}) {
      --section-index: #{$i};
      z-index: calc(var(--section-index) + 1);
    }
  }
}
